*,
::after,
::before {
	box-sizing: border-box;
}

button {
	border: none;
}

label {
	display: inline-block;
	cursor: unset;
}

ol,
ul {
	padding-left: 2rem;

	ol,
	ul {
		margin-bottom: 0;
	}
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
}
