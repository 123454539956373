.master-container {
    background-color: var(--background-color-primary);

}

.date-container {
    display: flex;
    flex-wrap: wrap;
    width:297px;
}

.container-ex{
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;
    background-color: var(--background-color-primary);
}

.monthYearContainer{
    display: flex;
    justify-content: space-between;
    width: 226px;
    height: 42px;
}

.time-oval-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 42px;
    top: 73px;
    left: 195.5px;
    border-radius: 25px;
    border: 1px solid #CFCFCF;
    background-color: white;
}

.month-date-font{
    font-weight: bold;
}

.calendar-container {
    background-color: var(--background-color-primary);
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    width: 100%; /* Ensure full width coverage */
    overflow: hidden; /* Hide overflow during animation */
}
@keyframes swipeLeft {

    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}

@keyframes swipeRight {
    0% { transform: translateX(0); }
    100% { transform: translateX(100%); }
}

@keyframes incomingLeft {
    0% { transform: translateX(100%); }
    100% { transform: translateX(0); }
}

@keyframes incomingRight {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(0); }
}

.animate-left {
    background-color: var(--background-color-primary);
    animation: swipeLeft 0.125s forwards;
}

.animate-right {
    background-color: var(--background-color-primary);
    animation: swipeRight 0.125s forwards;
}

.incoming-left {
    background-color: var(--background-color-primary);
    animation: incomingLeft 0.125s forwards;
}

.incoming-right {
    background-color: var(--background-color-primary);
    animation: incomingRight 0.125s forwards;
}
