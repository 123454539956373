:root {
	--font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", Arial, sans-serif;
	--font-size: 16px;
	--font-weight-bold: 700;
	--font-weight-semi-bold: 600;
	--font-weight-light: 400;
	--common-layout-padding-x: 24px;
	--common-layout-padding-y: 24px;
	--common-animation-curve: cubic-bezier(0.4, 0, 0.2, 1);
	--safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	--button-border-width: 1px;
	--button-border-radius: 9999px;
	--button-lg-padding-x: 48px;
	--button-lg-padding-y: 12.5px;
	--button-md-padding-x: 24px;
	--button-md-padding-y: 9.5px;
	--button-sm-padding-x: 24px;
	--button-sm-padding-y: 8px;
	--button-lg-font-size: 14px;
	--button-md-font-size: 14px;
	--button-sm-font-size: 12px;
	--button-font-weight: 600;
	--link-font-weight: 600;
	--link-text-decoration: underline;
	--item-font-size: 14px;
	--item-font-weight: 400;
	--item-line-height: 18px;
	--item-start-font-weight: 400;
	--item-end-font-weight: 600;
	--section-header-margin-bottom: 16px;
	--section-header-font-size: 14px;
	--section-header-font-weight: 600;
	--card-border-radius: 7px;
	--card-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
	--quick-action-content-max-width: 75px;
	--national-flag-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.2);
}

// Theme
body,
body[theme="light"] {
	--theme-color-primary: #ffc600;
	--text-color-primary: #000;
	--text-color-secondary: #7c7c7d;
	--text-color-red: #e35d5d;
	--text-color-green: #5dbc7d;
	--text-color-gray: #989899;
	--border-color: #cfcfcf;
	--background-color-primary: #efeff3;
	--anchor-text-color: #2892e9;
	--link-text-color: #000;
	--button-background-color: #ffc600;
	--button-text-color: #000;
	--button-primary-background-color: #ffc600;
	--button-primary-text-color: #000;
	--button-primary-disabled-background-color: #ffeca9;
	--button-primary-disabled-text-color: #cfcfcf;
	--button-secondary-border-color: #cfcfcf;
	--button-secondary-background-color: #fff;
	--button-secondary-text-color: #000;
	--button-red-background-color: #e35d5d;
	--button-red-text-color: #fff;
	--button-green-background-color: #5dbc7d;
	--button-green-text-color: #fff;
	--button-border-color: transparent;
	--input-border-color: #000;
	--input-success-border-color: #5dbc7d;
	--input-error-border-color: #e35d5d;
	--bordered-input-border-color: #cfcfcf;
	--bordered-input-background-color: #fff;
	--bordered-input-placeholder-color: #cfcfcf;
	--bordered-select-background-color: #fff;
	--switch-track-background-color: #ccc;
	--switch-track-toggled-background-color: #4cd863;
	--switch-thumb-background-color: #fff;
	--item-text-color: #000;
	--item-border-color: #cfcfcf;
	--section-header-text-color: #000;
	--card-background-color: #fff;
	--dialog-background-color: #fff;
	--dialog-backdrop-background-color: rgba(0, 0, 0, 0.8);
	--snackbar-success-text-color: #fff;
	--snackbar-success-background-color: #5dbc7d;
	--snackbar-error-text-color: #fff;
	--snackbar-error-background-color: #e35d5d;
	--snackbar-info-text-color: #fff;
	--snackbar-info-background-color: #7c7c7d;
	--highlight-text-background-color: #ffeca9;
	--tag-info-text-color: #fff;
	--tag-info-background-color: #4597a0;
	//--select-drawer-selected-text-color: ;
	--select-drawer-selected-background-color: #f8f8f8;
	--national-flag-border-color: #fff;
}

body[data-theme="dark"] {
}
