@import "styles/variables";
@import "styles/reboot";
@import "styles/font";

html,
body,
#root,
.app,
.app-animate-container {
	height: 100%;
	background-color: var(--background-color-primary);
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
}

/*
For native app use

html,
.app-animate-container {
	height: calc(
		100% + constant(safe-area-inset-top) + constant(safe-area-inset-bottom)
	);
	height: calc(100% + env(safe-area-inset-top) + env(safe-area-inset-bottom));
}*/

body {
	margin: 0;
	font-size: var(--font-size);
	font-family: var(--font-family);
	//overflow: auto;
	user-select: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: var(--anchor-text-color);
	cursor: pointer;
}

.app-animate-container {
	position: absolute;
	width: 100%;
}

.mbb-icon {
	user-select: none;
}

#root .notistack-SnackbarContainer {
	width: 100%;
	max-width: 100%;
	bottom: 0;

	> div {
		width: 100%;

		.notistack-CollapseWrapper {
			padding: 0;
		}
	}
}

.sortable-ghost {
	visibility: hidden;
}
