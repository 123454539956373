@font-face {
	font-family: "Montserrat";
	src: local("Montserrat"),
		url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-weight: 700;
	src: local("Montserrat"),
		url(../assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-weight: 600;
	src: local("Montserrat"),
		url(../assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
	font-family: "Montserrat";
	font-weight: 400;
	src: local("Montserrat"),
		url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
